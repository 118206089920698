module.exports = {
  comm: {
    home: '홈',
    pricing: '가격',
    blog: '음성 복제 및 API', // 更新
    language: '언어:',
    select_language: '언어 선택',
    txt2voice: '텍스트 음성 변환',
    voice2txt: '음성 텍스트 변환',
    voiceclone: '음성 복제',
    video2txt: '비디오 텍스트 변환',
    footer_help: '도움이 필요하신가요? 이메일을 보내주세요:',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: '로그인',
    login_desc: 'TikTok 음성 생성기 계정에 접속하려면 로그인하세요',
    logout: '로그아웃',
  },
  txt2voice: {
    title: 'TikTok 음성 생성기: 재미있는 TikTok AI 음성 생성',
    description: '무료로 재미있는 TikTok AI 음성을 생성하고 다운로드할 수 있습니다. jessie 음성, ghostface 음성, C3PO 음성 등.',
    keywords: 'TikTok 음성 생성기,TikTok AI 음성,Tik Tok 음성 생성기',
    main_title_p01: 'TikTok 음성 생성기',
    main_title_p02: '재미있는 TikTok 음성 생성: jessie 음성, C3PO 음성, ghostface 음성',
    main_textarea_holder: '여기에 텍스트를 입력하거나 붙여넣으세요',
    main_genvoice: '생성',
    main_generating: '생성 중',
    main_input_empty: '텍스트를 입력하거나 붙여넣으세요',
    daily_usage_limit_msg: '1) 오늘 최대 사용 한도에 도달했습니다. 내일 다시 오세요. 2) 긴급하게 더 많은 사용이 필요한 경우, 이메일로 연락해 주세요.',
    text_max_prompt1: '단어 수를 제한하세요',
    text_max_prompt2: '단어 이하로!',
    popup_nologin_title: '구독 플랜을 업그레이드하세요',
    popup_nologin_desc: '프리미엄 구독으로 업그레이드하여 더 많은 음성 생성 시간을 확보하세요.',
    popup_nologin_btntext: '구독 플랜 살펴보기',
    popup_nosub_title: '구독 플랜을 업그레이드하세요',
    popup_nosub_desc: '프리미엄 구독으로 업그레이드하여 더 많은 음성 생성 시간을 확보하세요.',
    popup_nosub_btntext: '구독 플랜 살펴보기',
    popup_sublimit_title: '구독이 소진되었습니다. 업그레이드해 주세요',
    popup_sublimit_desc: '프리미엄 기능과 무제한 액세스로 경험을 향상시키세요.',
    popup_sublimit_btntext: '더 구매하러 가기',
  },
  pricing: {
    new_features_alert: "📣 유료 회원을 위한 🎙️ 음성 복제 및 🤖 API 서비스를 출시했습니다! (결제 후, 이 기능들에 대한 도움이 필요하시면 tiktokaivoicetool@gmail.com로 이메일을 보내주세요). 또한, 맞춤 요청이 있으시면 언제든지 이메일로 문의해 주세요😃", // 新增
    pricing_title: '개인 크리에이터부터 대기업까지, 여러분을 위한 플랜이 있습니다.',
    pricing_desc: '100개 이상의 자연스럽고 인간다운 목소리. 15개 이상의 언어. 그리고 거의 모든 사람을 위한 최저 가격',
    sub_free_name: '무료',
    sub_free_desc: '최첨단 AI 오디오를 시도해보고 싶은 개인을 위한 플랜',
    sub_free_content: [
      '월 1000자 제한',
      '하루 5회 생성',
    ],
    sub_starter_name: '스타터',
    sub_starter_desc: '글로벌 청중을 위해 프리미엄 콘텐츠를 제작하는 크리에이터를 위한 플랜',
    sub_starter_content: [
      '1개의 음성 복제',
      '월 300,000자 제한 (약 7시간의 오디오)',
      '무제한 다운로드',
      '72시간 이내 이메일 지원',
      '새로운 음성 및 기능에 대한 우선 접근',
    ],
    sub_pro_name: '프로',
    sub_pro_desc: '콘텐츠 제작을 확장하는 크리에이터를 위한 플랜',
    sub_pro_content: [
      'API 접근',
      '3개의 음성 복제',
      '월 1,000,000자 제한 (약 24시간의 오디오)',
      '무제한 다운로드',
      '48시간 이내 이메일 지원',
      '새로운 음성 및 기능에 대한 우선 접근',
    ],
    period_year: '연간',
    period_month: '월간',
    period_month_short: '월',
    billed_year: '연간 청구',
    most_popular: '가장 인기',
    discount_quantity: '$48 절약',
    buy_btntext: '시작하기',
    pay_succ: '결제 성공!',
    pay_succ_desc: '구매해 주셔서 감사합니다. 거래가 성공적으로 처리되었습니다.',
    pay_succ_btntext: 'TikTok Voice 사용 시작하기',
    pay_fail: '결제 실패!',
    pay_fail_desc: "죄송합니다. 거래를 처리할 수 없었습니다. 다시 시도하거나 지원 팀에 문의해 주세요 (tiktokaivoicetool@gmail.com).",
    pay_fail_btntext: '홈페이지로 돌아가기',
  },
  setting: {
    setting: '설정',
    setting_title: '여기에서 계정, 사용량 및 구독을 관리하세요.',
    basic_info: '기본 정보', // 新增，放在 setting_title 下方
    user_name: '이름',
    user_email: '이메일',
    user_account: '계정',
    user_subscript: '현재 플랜',
    user_usage: '사용량',
    manage_subscript: '구독 관리',
    use_limit_promote: "월 10,000자 제한 중 5,000자를 사용하셨습니다.",
    not_logged_in: '로그인되지 않았습니다', // 新增
    go_to_login: '로그인으로 이동 >>', // 新增
  },
  login: { // 新增 login 对象，放在 setting 下方
    title: "TikTok 음성 생성기",
    subtitle: "TikTok 음성 생성기 계정에 접속하려면 로그인하세요",
    alreadyLoggedIn: "이미 로그인되어 있습니다",
    goToHome: "홈페이지로 이동"
  },
  faq: {
    quest1: 'TikTok 음성 생성기 - 최고의 TikTok AI 음성 도구',
    answer1_1: '이것은 최신 tiktok tts 기술로 개발된 tiktok AI 음성 생성기 도구로, jessie 음성(여성 음성), siri 음성, ghostface 음성, C3PO 음성(로봇 음성), 깊은 음성(스토리텔러), skye 음성, 따뜻한 음성, bestie 음성, 영웅 음성(chiris 음성), 공감 음성, 진지한 음성, joey 음성, stitch 음성, 폭풍병사 음성(스타워즈), 로켓 음성(가디언즈 오브 갤럭시) 등의 tiktok 음성을 생성할 수 있습니다.',
    answer1_2: '곧 지원될 tiktok AI 음성에는 derek 음성, trickster 음성, austin butler 음성, 뉴스 리포터 음성, adam 음성, 개 음성, miley cyrus 음성, alexa 음성, 외계인 음성, 동물 음성, 아기 음성, 컴퓨터 음성, 다람쥐 음성, 에코 음성, npr 음성이 있습니다.',
    answer1_3: '또한 tiktok 음성 생성기 도구는 중국어, 일본어, 한국어, 베트남어, 태국어, 힌디어, 페르시아어, 러시아어, 독일어, 프랑스어, 루마니아어, 체코어, 스페인어, 포르투갈어, 벵골어, 이탈리아어, 아랍어, 우르두어, 전통 중국어, 말레이어를 포함한 여러 언어를 지원합니다.',
    answer1_4: '특정 음성이 급히 필요하시면 이메일을 보내주세요.',
    
quest2: 'TikTok 음성 생성기의 장점은 무엇입니까?',
    answer2_1: '- TikTok 음성 생성기 도구는 TikTok 비디오에서 자주 사용되는 다양한 유형의 음성을 생성할 수 있습니다.',
    answer2_2: '- 이것은 최신 AI 음성 생성기로, 사람과 유사한 tiktok tts 음성을 생성할 수 있습니다.',
    answer2_3: '- PC에서 비디오 편집이 더 편리합니다.',
    answer2_4: '- 현재 TikTok APP tts에서 찾을 수 없는 몇 가지 음성을 사용할 수 있습니다.',
    
quest3: 'TikTok 음성 생성기를 사용하는 방법은 무엇입니까?',
    answer3_1: 'TikTok 음성 생성기는 매우 사용하기 쉽습니다:',
    answer3_2: '- 언어와 음성 악센트를 선택합니다.',
    answer3_3: '- 음성으로 변환할 텍스트를 입력 상자에 입력합니다.',
    answer3_4: '- 생성 버튼을 누르고 몇 초 동안 기다립니다.',
    answer3_5: '- TikTok AI 음성을 재생하거나 다운로드합니다.',
    
quest4: '가장 유명하고 재미있는 tiktok 음성은 무엇입니까?',
    answer4_1: '가장 유명한 남성 tiktok 음성은 이야기꾼이라고 불리는 깊은 음성입니다.',
    answer4_2: '가장 재미있는 tiktok 음성으로는 개인적으로 ghostface 음성과 C3PO 음성을 추천합니다. 이들은 매우 인식하기 쉬워서 재미있는 TikTok 비디오의 더빙에 자주 사용됩니다.',
    
quest5: '가장 짜증나는 TikTok 음성은 무엇니까?',
    answer5_1: '진정으로 "짜증나는" tiktok 음성은 없다고 생각합니다.',
    answer5_2: '이것은 음성이 사용되는 맥락과 내용에 더 관련이 있으며, 이는 일부 사람들에게 불편함을 줄 수 있습니다. 예를 들어, TikTok에서 특정 기간 동안 너무 자주 등장했기 때문에 jessie 음성이 짜증난다고 느끼는 사람들이 있습니다. 그러나 그 음색과 품질로 인해 그것은 실제로 훌륭한 tiktok 음성 효과입니다. 또한 그 뒤에 있는 tiktok 성우는 매우 전문적이며 그녀 자신도 TikTok에 비디오를 게시합니다.',
    answer5_3: '따라서 모든 사람들이 걱정과 선입견을 버리고, 이 도구의 음성을 여러 번 듣고, 인내심을 가지고 자신에게 가장 적합한 tiktok 음성 효과를 찾으시기 바랍니다.',
    
quest6: 'TikTok 음성 생성기를 사용하여 jessie 음성을 생성하려면 어떻게 해야 합니까?',
    answer6_1: '- TikTok 음성 생성기 도구의 첫 번째 드롭다운 메뉴에서 English (US)를 선택한 다음 다른 드롭다운 메뉴에서 Jessie 음성(여성 음성)을 선택합니다.',
    answer6_2: '- 텍스트를 입력 상자에 입력하고 생성 버튼을 클릭합니다.',
    answer6_3: '- 몇 초에서 최대 열 초 동안 기다리면 AI 음성을 들을 수 있습니다. 작업 표시줄에서 아래쪽 화살표 버튼을 찾아 클릭하여 다운로드합니다.',
    
quest7: 'TikTok 음성 생성기를 사용하여 C3PO 음성을 생성하려면 어떻게 해야 합니까?',
    answer7_1: '- TikTok 음성 생성기 도구의 첫 번째 드롭다운 메뉴에서 English (US)를 선택한 다음 다른 드롭다운 메뉴에서 C3PO 음성(로봇 음성)을 선택합니다.',
    answer7_2: '- 텍스트를 입력 상자에 입력하고 생성 버튼을 클릭합니다.',
    answer7_3: '- 몇 초에서 최대 열 초 동안 기다리면 AI 음성을 들을 수 있습니다. 작업 표시줄에서 아래쪽 화살표 버튼을 찾아 클릭하여 다운로드합니다.',
    
quest8: 'TikTok 음성 생성기를 사용하여 ghostface 음성을 생성하려면 어떻게 해야 합니까?',
    answer8_1: '- TikTok 음성 생성기 도구의 첫 번째 드롭다운 메뉴에서 English (US)를 선택한 다음 다른 드롭다운 메뉴에서 ghostface 음성을 선택합니다.',
    answer8_2: '- 텍스트를 입력 상자에 입력하고 생성 버튼을 클릭합니다.',
    answer8_3: '- 몇 초에서 최대 열 초 동안 기다리면 AI 음성을 들을 수 있습니다. 작업 표시줄에서 아래쪽 화살표 버튼을 찾아 클릭하여 다운로드합니다.',
    
quest9: 'TikTok 음성 생성기를 사용하여 siri 음성을 생성하려면 어떻게 해야 합니까?',
    answer9_1: '- TikTok 음성 생성기 도구의 첫 번째 드롭다운 메뉴에서 English (US)를 선택한 다음 다른 드롭다운 메뉴에서 siri 음성을 선택합니다.',
    answer9_2: '- 텍스트를 입력 상자에 입력하고 생성 버튼을 클릭합니다.',
    answer9_3: '- 몇 초에서 최대 열 초 동안 기다리면 AI 음성을 들을 수 있습니다. 작업 표시줄에서 아래쪽 화살표 버튼을 찾아 클릭하여 다운로드합니다.',
    
quest10: 'TikTok 비디오에 tiktok 음성 효과를 추가하려면 어떻게 해야 합니까?',
    answer10_1: 'TikTok에서 공식 tiktok 음성을 사용하는 방법을 알고 싶다면, 이 주제에 대한 전용 블로그 게시물을 곧 작성하겠습니다.',
    answer10_2: '여기에서는 tiktok 음성을 생성하고 다운로드한 후 TikTok에 게시하는 방법을 설명하겠습니다.',
    answer10_3: '1. PC에서 tiktok 음성을 생성하고 TikTok이나 휴대전화의 다른 비디오 편집 앱에 업로드하려면 음성 파일을 휴대전화로 전송해야 합니다. iPhone의 경우 airDrop을 사용하여 전송할 수 있습니다. Android 휴대전화의 경우 프로세스에 익숙하지 않지만, 방법과 도구를 찾을 수 있습니다.',
    answer10_4: '2. 음성 파일을 휴대전화로 송한 후 TikTok을 엽니다:',
    answer10_5: '- 인터페이스 하단의 "+" 버튼을 탭하여 휴대전화의 비디오를 선택합니다.',
    answer10_6: '- 비디오를 업로드한 후 화면 오른쪽에 있는 사각형 아이콘을 찾아 편집 페이지로 들어갑니다.',
    answer10_7: '- 페이지 하단에 있는 "음성 추가" 버튼을 찾아 방금 전송한 음성 파일을 선택합니다.',
    answer10_8: '- 이 인터페이스에서 비디오에 몇 가지 조정을 하고, 오른쪽 상단의 버튼을 클릭하여 게시합니다.',
    
quest11: 'TikTok 음성은 무료입니까?',
    answer11: '네, 이것은 무료 tiktok AI 음성 텍스트에서 음성으로 변환하는 생성기 도구입니다.',
    
quest12: '내 TikTok 비디오에 인기 있는 TikTok 음성을 생성할 수 있는 장소는 어디입니까?',
    answer12: 'https://tiktokvoice.net/을 방문하여 tiktok AI 음성을 비디오에 추가할 수 있습니다.'
  },
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}